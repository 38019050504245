<script>
import Vue from 'vue';
import GlobalVue from '@helper/Global.vue';
import moment from 'moment';
import Gen from '@helper/Gen';
import Croppa from 'vue-croppa';
import swal from 'sweetalert';
import VTooltip from 'v-tooltip'
import {VueMasonryPlugin} from 'vue-masonry';
import {vueTopprogress} from 'vue-top-progress'

/* Vue plugins */
Vue.use(VueMasonryPlugin)
Vue.use(Croppa);
Vue.use(vueTopprogress)

moment.locale("id")
global.moment = moment

/* CSS */

import "@frontend/css/custom.scss"
import "@frontend/css/responsive.scss"

/* JS */
import "@plugins/jquery/dist/jquery.min.js"
import "@plugins/jquery-validation/jquery-validation.js"
import "@helper/general_function.js"
import "@plugins/extension.js"
import "@frontend/js/main.js"

/* Vue Component */
Vue.component("ImagePreview",()=>import("@backend/components/ImagePreview.vue"))
Vue.component("Component404",()=>import("@frontend/components/Component404.vue"))
// Vue.component("VInfo", ()=>import("@frontend/components/VInfo.vue"))
// Vue.component("VAlert", ()=>import("@frontend/components/VAlert.vue"))
// Vue.component("BreadCrumb", ()=>import("@frontend/part/BreadCrumb.vue"))

global.$ = global.jQuery
global.Gen = Gen
global.App.user = null

export default {
  name:"FoMain",
  extends: GlobalVue,
  async mounted(){
    window.history.scrollRestoration = 'manual'
    /* CSS */
    Gen.loadCss("/frontend/css/bootstrap.css")
    Gen.loadCss("/frontend/css/style.css")
    Gen.loadCss("/frontend/css/loader.css?v=0.8")
    Gen.loadCss("/frontend/css/swiper.css")
    Gen.loadCss("/frontend/css/dark.css")
    Gen.loadCss("/frontend/css/font-icons.css")
    Gen.loadCss("/frontend/css/fonts/font_style.css")
    Gen.loadCss("/frontend/css/aos.css")
    Gen.loadCss("/frontend/css/magnific-popup.css")
    Gen.loadCss("/frontend/css/custom_ta.css?v=0.8")
    Gen.loadCss("/frontend/css/responsive.css")
    Gen.loadCss("/frontend/css/responsive_l9.css?v=0.8")

    /* JS */    
    await Gen.loadScript("/frontend/js/plugins.js")
    await Gen.loadScript("/frontend/js/aos.js")
    await Gen.loadScript("/frontend/js/functions.js?v=0.3")
    
        global.$ = global.jQuery

        /* Ready Loaded */
        $(document).ready(()=>{
            this.$root.ready = true
        })

        /* Load Variable */
        this.refreshVariableFo()

        var self = this
        $(document).on("click", "a", function(e){
            if($(this).is("[target]")) return;
            if($(this).attr("data-lightbox")) return;
            if($(this).attr("href")=="javascript:;") return;
            if($(this).attr("href")[0]=="#") return;
            if($(this).attr("href").indexOf(location.origin)>-1){
                e.preventDefault();
                self.$router.push($(this).attr("href").replace(location.origin, ""))
            }
            if($(this).attr("href").indexOf(":")<0){
                e.preventDefault();
                if(self.$route.fullPath!=$(this).attr("href")) self.$router.push($(this).attr("href"))
            }
        })

        this.$root.topProgress = this.$refs.topProgress
        this.$root.topProgress.start()
        this.apiGetProduct()
    },
    components: {
        vueTopprogress
    },
    methods:{
        apiGetProduct(){
            Gen.apirest('/product', {}, (err, resp) => {
                if (err) console.log(err)
                this.$root.store.productMenu = resp.data
            })
        },
    },
    watch: {
        $route(){
            if(typeof $ != 'undefined') $("html,body").scrollTop(0)
            AOS.init();
            this.$root.topProgress.start()
            $("#primary-menu > ul").removeClass('d-block')
        }
    },
}

$(document).scroll(function() {
    var y = $(this).scrollTop();
    if (y > 200) {
        $('.bottom_navigation').fadeIn();
    } else {
        $('.bottom_navigation').fadeOut();
    }
});
function currentItem(id) {
  var x = id;
  document.getElementById(x).classList.add("current");
}
</script>

<template>
    <div id="FoMain">
        <vueTopprogress ref="topProgress" v-bind="{color:'#ff0000'}"></vueTopprogress>
        <router-view/>
    </div>
</template>