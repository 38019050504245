/* eslint-disable no-unused-vars */
window.toslug = function(text){
	return text.toLowerCase().replace(/ /g,'-').replace(/[^\w-]+/g,'');
}

window.alphaOnly = function(e){
	return /^[a-zA-Z ]$/g.test(String.fromCharCode(e.which));
}

window.fullNameKey = function(e){
	if(/^(8)$/g.test(e.keyCode)) return true;
	return /^[a-zA-Z ]$/g.test(String.fromCharCode(e.which));
}

window.numberKey = function(e){
	return /^[0-9]$/g.test(String.fromCharCode(e.which));
}

window.mobileKey = function(e){
	return /^[0-9]$/g.test(String.fromCharCode(e.which));
}

window.phoneKey = function(e){
	return /^[0-9\s-]$/g.test(String.fromCharCode(e.which));
}

window.emailKey = function(e){
	if(/^(8)$/g.test(e.keyCode)) return true;
	return /^[a-zA-Z0-9@+._-]$/g.test(String.fromCharCode(e.which));
}

window.maxWord = function(e, limit){
	if(e.key===" "&&e.target.value.wordLength() == limit) return false
	if(e.key===" "&&e.key===e.target.value.substr(-1)) return false
	return true
}

// default validation message
$.extend($.validator.messages, {
	required: function(p,el){
		var element = $(el)
		var placement = $("[error='"+element.attr('name')+"']")
		var label = placement.attr('label') || element.attr('name')
		var msg = ['radio','checkbox'].indexOf(element.attr('type'))>-1?"Ceklist":"Input"
		return msg+" "+label+"."
	},
	email: "Input email yang benar.",
	number: "Input angka yang benar.",
	creditcard: "Please enter a valid credit card number.",
	equalTo: function(p,el){
		var element = $(el)
		var placement = $("[error='"+element.attr('name')+"']")
		var label = placement.attr('label') || element.attr('name')
		return label+" tidak sesuai."
	},
	maxlength: $.validator.format("Input maksimal {0} karakter."),
	minlength: $.validator.format("Input minimal {0} karakter."),
	rangelength: $.validator.format("Input antara {0} s/d {1} karakter."),
	range: $.validator.format("Input antara {0} s/d {1}."),
	max: $.validator.format("Input maksimal {0}."),
	min: function(p,el){
		var element = $(el)
		var placement = $("[error='"+element.attr('name')+"']")
		var label = placement.attr('label') || element.attr('name')
		return placement.data('msg-min') || "Input min "+p+"."
	},
	regexPassword(p,el){
		var val = el.value
		if(!/[a-z]+/i.test(val)) return "Password tidak mengandung karakter."
		if(!/[1-9]+/i.test(val)) return "Password tidak mengandung angka."
		if(!/[^\w]+/i.test(val)) return "Password tidak mengandung spesial karakter."
		if(/\s+/i.test(val)) return "Password tidak boleh mengandung spasi."
		return "Password tidak valid"
	}
});
$.validator.setDefaults({
	ignore: ".novalidate",
	// any other default options and/or rules
});
$.extend($.validator.methods, {
	regexPassword(value, element, regexp) {
		if(!value) return true
		if(!/[a-z]+/i.test(value)) return false
		if(!/[1-9]+/i.test(value)) return false
		if(!/[^\w]+/i.test(value)) return false
		if(/\s+/i.test(value)) return false
		return true
	}
})
$(document).on("click", ".view_password .btn_view", function(){
	var btn = $(this);
	var input = $(this).parents(".view_password").find("input")
	if(btn.hasClass("icon-eye-open")){
		btn.removeClass("icon-eye-open").addClass("icon-eye-close")
		input.attr("type", "text")
	}else{
		btn.removeClass("icon-eye-close").addClass("icon-eye-open")
		input.attr("type", "password")
	}
})
